import React from "react"
import { Box } from "@mui/material"

interface Props {
  normal: React.ReactNode
  hover: React.ReactNode
  sx?: React.CSSProperties
}

const HoverIcon = (props: Props) => {
  const [hovering, setHovering] = React.useState(false)
  return (
    <Box
      {...props}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {hovering ? props.hover : props.normal}
    </Box>
  )
}

export default HoverIcon
