import React from "react"
import { Box } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { extractStringFirstToken } from "../../utils/index"

type ScreenLocationBoxProps = {
  name: string
  selected: boolean
  onSelect: (name: string) => void
  disabled?: boolean
}

const ScreenLocationBox = ({
  name,
  selected,
  onSelect,
  disabled = false
}: ScreenLocationBoxProps) => {
  const handleClick = React.useCallback(() => {
    if (!disabled) {
      onSelect(name)
    }
  }, [onSelect, name, disabled])
  return (
    <Box
      onClick={handleClick}
      sx={{
        height: "91px",
        width: "162px",
        backgroundColor: selected ? "secondary.light" : "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2px",
        border: `${selected ? "5px double #363AA0" : "3px double #8C8C96"}`,
        fontSize: 16,
        opacity: disabled ? 0.5 : 1,
        "&:hover": {
          backgroundColor: !disabled ? "secondary.light" : "inherit",
          cursor: disabled ? "not-allowed" : "pointer"
        },
        color: selected ? "#363AA0" : ""
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {selected && (
          <CheckCircleIcon sx={{ color: "primary.light", fontSize: "14px" }} />
        )}
        {name ? extractStringFirstToken(name) : null}
      </Box>
    </Box>
  )
}

export default ScreenLocationBox
