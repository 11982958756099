import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./ui/App"
import reportWebVitals from "./reportWebVitals"
import ShowCookieWarning from "./ui/ShowCookieWarning"
import * as Sentry from "@sentry/react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom"

Sentry.init({
  dsn: "https://99439d97a74a9f1eef30b37338a97421@o4506361348358144.ingest.sentry.io/4506361355960320",
  environment: process.env.REACT_APP_SENTRY_STAGE,
  integrations: [
    new Sentry.Replay(),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

let localStorageAccessible = true

try {
  localStorage.setItem("test-cookie", "cookie")
  localStorage.removeItem("test-cookie")
} catch (e) {
  localStorageAccessible = false
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    {localStorageAccessible ? <App /> : <ShowCookieWarning />}
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
