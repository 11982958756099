import React from "react"
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@mui/material"
import { useFormContext, useController } from "react-hook-form"
import StoreAreaDisplay from "../StoreAreaDisplay"
import { StoreArea } from "../../../models/storearea"
import ConfirmationModal from "../ConfirmationModal"

interface Props {
  storeAreas: StoreArea[]
  editable: boolean
}

const StoreAreaSelector = ({ editable, storeAreas }: Props) => {
  const { control, watch, setValue } = useFormContext()
  const [openSwitchStoreAreaModal, setOpenSwitchStoreAreaModal] =
    React.useState(false)
  const [tempStoreArea, setTempStoreArea] = React.useState<StoreArea | null>(
    null
  )

  const storeArea = watch("storeArea")
  const activeStores = watch("activeStores")
  const selectedScreenLocation = watch("selectedScreenLocation")
  const file = watch("file")

  const { field: storeAreaField } = useController({
    name: "storeArea",
    control
  })

  const handleSelectStore = React.useCallback(
    (newStoreArea: StoreArea) => {
      const isStoreAreaChanging =
        storeArea && storeArea.storeAreaId !== newStoreArea.storeAreaId
      const hasSelectedStores = activeStores?.length > 0
      const hasFile = file?.asset !== undefined
      const screenLocationSelected = selectedScreenLocation !== undefined

      if (
        isStoreAreaChanging &&
        (hasSelectedStores || hasFile || screenLocationSelected)
      ) {
        setTempStoreArea(newStoreArea)
        setOpenSwitchStoreAreaModal(true)
      } else {
        storeAreaField.onChange(newStoreArea)
      }
    },
    [storeArea, activeStores, file, storeAreaField, selectedScreenLocation]
  )

  const handleConfirmChange = () => {
    if (tempStoreArea) {
      storeAreaField.onChange(tempStoreArea)
      setValue("activeStores", [])
      setValue("file", null)
      setValue("storeSearchTerm", "")
      setValue("selectedScreenLocation", undefined)
      setTempStoreArea(null)
    }
    setOpenSwitchStoreAreaModal(false)
  }

  const handleSwitchStoreAreaModalClose = () => {
    setOpenSwitchStoreAreaModal(false)
  }
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "720px"
      }}
      data-cy="store-area-selector"
    >
      <>
        <Typography
          sx={{
            marginBottom: "16px",
            fontSize: "18px"
          }}
          variant="body1"
          data-cy="store-area-selector"
        >
          Zones
        </Typography>
        <TableContainer
          sx={{
            border: "1px solid",
            borderColor: "secondary.light",
            borderRadius: "8px",
            overflowY: "auto",
            maxHeight: "396px"
          }}
        >
          <Table
            sx={{
              "& .MuiTableCell-root": {
                paddingY: 0,
                height: "36px"
              }
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Placements</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {storeAreas
                .filter((area) => area.isInstalled)
                .map((area) => (
                  <StoreAreaDisplay
                    isSelected={storeArea?.storeAreaId === area.storeAreaId}
                    key={area.storeAreaId}
                    storeArea={area}
                    onSelectStore={handleSelectStore}
                    disabled={!editable}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>

      <ConfirmationModal
        open={openSwitchStoreAreaModal}
        title="Targeting and creative will be reset"
        content="You will need to re-select your targeting and re-upload the existing or new creative if you want to select a different store area. Do you want to proceed?"
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        confirmButtonVariant="primary"
        onCancel={handleSwitchStoreAreaModalClose}
        onConfirm={handleConfirmChange}
      />
    </Box>
  )
}

export default StoreAreaSelector
