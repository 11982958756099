import { createTheme } from "@mui/material"

declare module "@mui/material/styles" {
  interface Palette {
    fileInput: Palette["primary"]
    disabled?: Palette["primary"]
    avatar?: Palette["primary"]
    hint?: Palette["primary"]
    asset?: Palette["primary"]
  }
  interface PaletteOptions {
    fileInput: PaletteOptions["primary"]
    disabled?: PaletteOptions["primary"]
    avatar?: PaletteOptions["primary"]
    hint?: PaletteOptions["primary"]
    asset?: PaletteOptions["primary"]
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ["Figtree", "sans-serif"].join(","),
    h1: {
      fontSize: "42px",
      fontWeight: "bold",
      lineHeight: "64px"
    },
    h2: {
      fontSize: "36px",
      fontWeight: "bold",
      lineHeight: "54px"
    },
    h3: {
      fontSize: "24px",
      fontWeight: "500",
      lineHeight: "48px"
    },
    h4: {
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "48px",
      color: "#28282F"
    },
    h5: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "22px"
    },
    h6: {
      fontSize: "24px",
      fontWeight: "semibold",
      lineHeight: "32px"
    },
    body1: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 500,
      color: "#52525B"
    },
    body2: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#52525B"
    },
    subtitle1: {
      fontSize: "12px",
      fontWeight: 600,
      color: "black"
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: 600,
      textTransform: "capitalize",
      color: "rgba(0, 0, 0, 0.5)"
    },
    caption: {
      fontSize: "11px",
      lineHeight: "11px",
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.5)"
    },
    button: {
      textTransform: "none",
      fontSize: "14px",
      lineHeight: "16.8px",
      borderRadius: "8px"
    }
  },
  palette: {
    primary: {
      main: "#00034E",
      light: "#363AA0",
      dark: "#171A6F",
      contrastText: "#BFBFC9"
    },
    secondary: {
      main: "#FFFFFF",
      light: "#DDDDE7",
      dark: "#8C8C96",
      contrastText: "#F0F0F5"
    },
    text: {
      primary: "#52525B",
      secondary: "#109AB5",
      disabled: "#BFBFC9"
    },
    error: {
      main: "#D80B00",
      dark: "#B70900"
    },
    success: {
      main: "#C8ECE7",
      light: "#03423A",
      dark: "#17564E"
    },
    fileInput: {
      main: "#E7FBFF",
      light: "#63636C",
      dark: "#28282F"
    },
    disabled: {
      main: "rgba(0, 0, 0, 0.26)"
    },
    avatar: {
      main: "#000030"
    },
    hint: {
      main: "#3AB6CE",
      dark: "#5E3F08",
      contrastText: "#FFE8B0"
    },
    info: {
      main: "#FFF7E7",
      light: "#BA7903",
      dark: "#976200"
    },
    asset: {
      main: "#0C0C0D"
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          lineHeight: "16.8px",
          fontWeight: 500,
          color: "#52525B",
          height: "36px",
          backgroundColor: "#F0F0F5",
          borderColor: "#DDDDE7",
          padding: 0,
          borderRadius: "6px"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "6px ",
          "& fieldset": {
            borderColor: "#BFBFC9"
          },
          "&.Mui-disabled": {
            backgroundColor: "#FFFFFF",
            color: "#BFBFC9",
            "& .MuiInputBase-input::placeholder": {
              color: "#8C8C96",
              opacity: 1
            }
          }
        },
        input: {
          "&::placeholder": {
            color: "#8C8C96",
            opacity: 1
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "6px"
        },
        select: {
          lineHeight: "1.4375em"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px"
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {},
      styleOverrides: {
        root: {
          "&.MuiButton-containedPrimary": {
            backgroundColor: "#171A6F",
            fontWeight: 600,
            color: "#FFFFFF"
          },
          "&.MuiButton-containedPrimary:hover": {
            backgroundColor: "#363AA0",
            fontWeight: 600
          },
          "&.MuiButton-containedPrimary:focus": {
            backgroundColor: "#171A6F",
            fontWeight: 600
          },
          "&.MuiButton-containedPrimary:disabled": {
            backgroundColor: "#DDDDE7",
            fontWeight: 600
          },
          "&.MuiButton-containedWarning": {
            backgroundColor: "#D80B00",
            fontWeight: 600
          },
          "&.MuiButton-containedWarning:hover": {
            backgroundColor: "#B70900",
            fontWeight: 600
          },
          "&.MuiButton-containedWarning:focus": {
            backgroundColor: "#B70900",
            fontWeight: 600
          },
          "&.MuiButton-outlinedPrimary": {
            border: "1px solid #BFBFC9",
            fontWeight: 600
          },
          "&.MuiButton-outlinedPrimary:hover": {
            border: "1px solid #8C8C96",
            fontWeight: 600
          },
          "&.MuiButton-outlinedPrimary:focus": {
            border: "1px solid #28282F",
            fontWeight: 600
          },
          "&.MuiButton-outlinedPrimary:disabled": {
            border: "1px solid #BFBFC9",
            fontWeight: 600,
            color: "#BFBFC9"
          }
        }
      }
    }
  }
})

export default theme
