import React from "react"
import { Box, Typography } from "@mui/material"
import { useController, useFormContext } from "react-hook-form"
import CreativeSectionHeader from "../CreativeSectionHeader"
import CampaignAssetUploader from "../CampaignAssetUploader"
import AssetPreview from "../AssetPreview"
import {
  CampaignFormModel,
  FileErrorType,
  Status
} from "../../../models/campaign"
import {
  isSupportedAsset,
  getAdjustedAspectRatioString,
  generateDurationList
} from "../../../utils"
import PQueue from "p-queue"

type HandleFileErrorFunction = (
  fileErrorType: FileErrorType | "clear-error",
  fileName: string
) => void

type UploadFileFunction = (
  name: string,
  aspectRatio: string,
  type: string,
  asset: File,
  duration?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<any>

type CampaignCreativeProps = {
  handleFileError: HandleFileErrorFunction
  uploadFile: UploadFileFunction
  uploadFileQueue: PQueue
  campaignStatus?: Status
}

const CampaignCreative: React.FC<CampaignCreativeProps> = ({
  handleFileError,
  uploadFile,
  uploadFileQueue,
  campaignStatus
}) => {
  const [isDragging, setIsDragging] = React.useState(false)
  const [fileDimension, setFileDimension] = React.useState("")
  const [videoDuration, setVideoDuration] = React.useState<number | undefined>(
    undefined
  )
  const { control, watch, setError } = useFormContext<CampaignFormModel>()
  const { field: campaignFileUploadField } = useController({
    name: "file",
    control
  })
  const storeArea = watch("storeArea")

  const campaignFiles = watch("file")

  const durationList = React.useMemo(() => {
    return generateDurationList(
      storeArea?.minCreativeDurationMs ?? 6000,
      storeArea?.maxCreativeDurationMs ?? 30000
    )
  }, [storeArea])

  const handleFileReupload = React.useCallback(
    async (newDuration: number) => {
      if (
        newDuration &&
        newDuration >= durationList[0] &&
        newDuration <= durationList[durationList.length - 1] &&
        campaignFiles?.asset &&
        campaignFiles?.assetType?.startsWith("image/")
      ) {
        uploadFileQueue.clear()
        uploadFileQueue.add(async () => {
          try {
            const uploadResponse = await uploadFile(
              campaignFiles.asset.name,
              storeArea.displayRotation,
              campaignFiles.assetType,
              campaignFiles.asset,
              newDuration
            )
            if (uploadResponse.error) {
              console.error(uploadResponse.error)
            } else {
              console.log("file reuploaded", uploadResponse)
            }
          } catch (error) {
            console.error("Upload failed", error)
            // eslint-disable-next-line no-empty
          } finally {
          }
        })
      }
    },
    [campaignFiles, uploadFileQueue]
  )

  const handleFile = (file: File) => {
    handleFileError("clear-error", file.name)

    if (!isSupportedAsset(file.type)) {
      handleFileError("unsupported-file-type", file.name)
      return
    }

    const objectUrl = URL.createObjectURL(file)
    const requiredAspectRatio = storeArea.displayRotation

    const onMediaLoad = async (
      width: number,
      height: number,
      duration?: number
    ) => {
      setFileDimension(`${width} x ${height}`)
      setVideoDuration(duration)

      const aspectRatio = getAdjustedAspectRatioString(width, height)

      if (
        duration !== undefined &&
        (duration < durationList[0] ||
          duration > durationList[durationList.length - 1] + 0.99)
      ) {
        handleFileError("invalid-video-duration", file.name)
        URL.revokeObjectURL(objectUrl)
        return
      }
      if (aspectRatio !== requiredAspectRatio) {
        handleFileError("invalid-asset-aspect-ratio-for-store", file.name)
        URL.revokeObjectURL(objectUrl)
        return
      }

      if (aspectRatio !== "16:9" && aspectRatio !== "9:16") {
        handleFileError("invalid-asset-aspect-ratio", file.name)
        URL.revokeObjectURL(objectUrl)
        return
      }

      if (aspectRatio === requiredAspectRatio) {
        if (aspectRatio === "16:9" && (width < 1920 || height < 1080)) {
          handleFileError("invalid-asset-resolution", file.name)
          URL.revokeObjectURL(objectUrl)
          return
        }

        if (aspectRatio === "9:16" && (width < 1080 || height < 1920)) {
          handleFileError("invalid-asset-resolution", file.name)
          URL.revokeObjectURL(objectUrl)
          return
        }
      }

      campaignFileUploadField.onChange({
        assetType: file.type,
        assetUrl: objectUrl,
        asset: file,
        assetId: null,
        ...(file.type !== "video/mp4" ? { duration: durationList[0] } : {})
      })

      const playDuration = file.type.startsWith("video/") ? duration : 6

      uploadFileQueue.add(async () => {
        try {
          const uploadResponse = await uploadFile(
            file.name,
            aspectRatio,
            file.type,
            file,
            playDuration
          )
          if (uploadResponse.error) {
            console.error(uploadResponse.error)
          } else {
            console.log("file uploaded", uploadResponse)
          }
        } catch (error) {
          console.error(error)
        } finally {
          URL.revokeObjectURL(objectUrl)
        }
      })
    }

    const onMediaError = (mediaType: string) => {
      setError("file", {
        type: "manual",
        message: `There was an error loading the ${mediaType} file.`
      })
      URL.revokeObjectURL(objectUrl)
    }

    if (file.type.startsWith("image/")) {
      const image = new Image()
      image.onload = () => onMediaLoad(image.naturalWidth, image.naturalHeight)
      image.onerror = () => onMediaError("image")
      image.src = objectUrl
    } else if (file.type.startsWith("video/")) {
      const video = document.createElement("video")
      video.onloadedmetadata = () =>
        onMediaLoad(video.videoWidth, video.videoHeight, video.duration)
      video.onerror = () => onMediaError("video")
      video.preload = "metadata"
      video.src = objectUrl
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      handleFile(file)
    }
    e.target.value = ""
  }

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0]
      handleFile(file)
    }
  }

  const handleDelete = () => {
    campaignFileUploadField.onChange({})
  }

  return (
    <Box display="flex" flexDirection="column" marginTop="24px" gap="20px">
      <Box display="flex" flexDirection="column" gap="20px">
        {campaignFiles?.assetUrl && campaignFiles?.assetType ? (
          <>
            <CreativeSectionHeader
              storeArea={storeArea}
              asset={campaignFiles?.assetUrl}
            />
            <AssetPreview
              campaignStatus={campaignStatus}
              assetUrl={campaignFiles.assetUrl}
              assetType={campaignFiles.assetType}
              assetDimension={fileDimension}
              videoDuration={videoDuration}
              handleDelete={handleDelete}
              onDurationChange={handleFileReupload}
            />
          </>
        ) : (
          <>
            <Typography variant="body1" sx={{ fontSize: "18px" }}>
              Asset
            </Typography>
            <CreativeSectionHeader storeArea={storeArea} />
            <CampaignAssetUploader
              accept="image/*,video/*"
              isDragging={isDragging}
              setIsDragging={setIsDragging}
              handleChange={handleChange}
              handleDrop={handleDrop}
            />
          </>
        )}
      </Box>
      <br />
    </Box>
  )
}

export default CampaignCreative
