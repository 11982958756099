import React from "react"
import { Grid, Typography } from "@mui/material"
import ScreenLocationBox from "./ScreenLocationBox"
import { ScreenLocation } from "src/models/screenlocation"
import { useController, useFormContext } from "react-hook-form"
import { alphabeticalSort } from "../../utils/index"

const removeDuplicates = (
  screenLocation: string,
  index: number,
  arr: string[]
) => {
  return index === 0 || arr[index - 1] !== screenLocation
}

type ScreenLocationSelector = {
  screenLocations: ScreenLocation[]
  disabled?: boolean
}

const ScreenLocationSelector = ({
  screenLocations,
  disabled
}: ScreenLocationSelector) => {
  const { control, watch } = useFormContext()
  const selectedScreenLocations: string[] | undefined = watch(
    "selectedScreenLocation"
  )

  const { field: selectedScreenLocationField } = useController({
    name: "selectedScreenLocation",
    control
  })

  const handleSelectedScreenLocation = React.useCallback(
    (description: string) => {
      const index =
        selectedScreenLocations?.findIndex(
          (screenLocation) => screenLocation === description
        ) ?? -1
      if (index === -1) {
        selectedScreenLocationField.onChange([
          ...(selectedScreenLocations ?? []),
          description
        ])
      } else {
        selectedScreenLocationField.onChange(
          (selectedScreenLocations ?? []).filter(
            (selectedScreenLocation) => selectedScreenLocation !== description
          )
        )
      }
    },
    [selectedScreenLocationField, selectedScreenLocations]
  )

  const filteredScreenLocations = React.useMemo(
    () =>
      screenLocations
        ?.map((screenLocation) => screenLocation.description)
        .filter(
          (description: string | null): description is string =>
            description !== null
        )
        .sort(alphabeticalSort)
        .filter(removeDuplicates),
    [screenLocations]
  )

  return (
    <>
      <Typography fontSize={18}>Screen locations</Typography>
      <Grid container spacing={2}>
        {filteredScreenLocations.map((screenLocation, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ScreenLocationBox
              onSelect={handleSelectedScreenLocation}
              name={screenLocation}
              selected={
                selectedScreenLocations?.includes(screenLocation) ?? false
              }
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ScreenLocationSelector
