/* eslint-disable indent */
import React, { useEffect, useRef } from "react"

import {
  useFormContext,
  Controller,
  useFieldArray,
  useWatch
} from "react-hook-form"
import {
  Box,
  TextField,
  IconButton,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  RadioGroup,
  Button,
  FormControlLabel,
  Radio
} from "@mui/material"
import TrashOutline from "../../icons/TrashOutline"
import CircleAdd from "../../icons/CircleAdd"

interface PropertyItem {
  value: string
  valueId?: string
  isEditable?: boolean
}
interface PropertyForm {
  propertyName: string
  propertyItems: PropertyItem[]
  propertyType: string
  notApplicable?: PropertyItem
}

interface PropertyValue {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValue[]
}

interface SetupStepProps {
  setStepComplete: React.Dispatch<React.SetStateAction<boolean>>
  isEditMode: boolean
  property?: PropertyApiResponse
}

const SetupStep = ({
  setStepComplete,
  isEditMode,
  property
}: SetupStepProps) => {
  const {
    control,
    register,
    setValue,
    clearErrors,
    formState: { errors, isValid }
  } = useFormContext<PropertyForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "propertyItems"
  })

  const previousPropertyItemsRef = useRef<PropertyItem[]>([])

  const propertyName = useWatch({
    control,
    name: "propertyName"
  })

  const propertyType = useWatch({
    control,
    name: "propertyType",
    defaultValue: "multi-select"
  })

  const propertyItems = useWatch({
    control,
    name: "propertyItems"
  })

  const notApplicable = useWatch({
    control,
    name: "notApplicable",
    defaultValue: {
      valueId: "notApplicable",
      value: "Not Applicable",
      isEditable: false
    }
  })

  useEffect(() => {
    // propertyItems is now tracked using useWatch
    if (!propertyItems || propertyItems.length === 0) {
      if (isEditMode && property) {
        setValue("propertyName", property.name)
        setValue("propertyType", property.type)

        // Set propertyItems from the backend response
        const items = property.values
          .filter(
            (value) => !(value.name === "Not Applicable" && !value.is_editable)
          )
          .map((value) => ({
            valueId: value.id.toString(),
            value: value.name,
            isEditable: value.is_editable
          }))

        setValue("propertyItems", items)

        // Handle "Not Applicable" value for multi-select types
        if (property.type === "multi-select") {
          const backendNotApplicable = property.values.find(
            (value) => value.name === "Not Applicable"
          )

          if (backendNotApplicable) {
            setValue("notApplicable", {
              valueId: backendNotApplicable.id.toString(),
              value: backendNotApplicable.name,
              isEditable: backendNotApplicable.is_editable
            })
          }
        }
      } else if (!isEditMode) {
        setValue("propertyItems", [{ value: "" }])
      }
    }
  }, [isEditMode, property])

  useEffect(() => {
    if (isValid && Object.keys(errors).length === 0) {
      setStepComplete(true)
    } else {
      setStepComplete(false)
    }
  }, [isValid, errors])

  const handlePropertyTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    setValue("propertyType", newValue)

    if (newValue === "boolean") {
      previousPropertyItemsRef.current = propertyItems
      setValue("propertyItems", [
        { value: "Yes", valueId: "yes" },
        { value: "No", valueId: "no" }
      ])
      clearErrors("propertyItems")
    } else if (newValue === "multi-select") {
      setValue(
        "propertyItems",
        previousPropertyItemsRef.current.length > 0
          ? previousPropertyItemsRef.current
          : [{ value: "" }]
      )
      clearErrors("propertyItems")
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: "560px"
      }}
    >
      <Box
        sx={{
          width: "48%",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "40px"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {isEditMode ? (
              <Typography variant="h5">Edit Property Name</Typography>
            ) : (
              <>
                <Typography variant="h5">Name your property</Typography>
                <Typography variant="body2" sx={{ fontWeight: 400 }}>
                  First, name your property you want to map your stores to
                </Typography>
              </>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography variant="body2" component="label">
              Property Name
              <Box
                component="span"
                sx={{ color: "error.main", marginLeft: "2px" }}
              >
                *
              </Box>
            </Typography>

            <TextField
              {...register("propertyName", {
                required: "Property Name is required",
                validate: (value) =>
                  value.trim() !== "" || "Property Name is required"
              })}
              fullWidth
              placeholder="Ex: region, district, size, pharmacy"
              error={!!errors.propertyName}
              helperText={errors.propertyName?.message}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {isEditMode ? (
              <Typography variant="h5">Edit Values</Typography>
            ) : (
              <>
                <Typography variant="h5">Add Values</Typography>
                <Typography variant="body2" sx={{ fontWeight: 400 }}>
                  Values are like subcategories within the larger property
                </Typography>
              </>
            )}
          </Box>
          {!isEditMode && (
            <Controller
              name="propertyType"
              control={control}
              defaultValue="multi-select"
              render={({ field }) => (
                <ToggleButtonGroup
                  value={field.value}
                  exclusive
                  onChange={handlePropertyTypeChange}
                  sx={{
                    height: "24px",
                    "& .MuiToggleButton-root.Mui-selected": {
                      backgroundColor: "primary.dark",
                      color: "secondary.main",
                      "&:hover": {
                        backgroundColor: "primary.dark"
                      }
                    },
                    "& .MuiToggleButton-root": {
                      backgroundColor: "secondary.contrastText",
                      color: "text.primary"
                    }
                  }}
                >
                  <ToggleButton value="multi-select">
                    Multiple Values
                  </ToggleButton>
                  <ToggleButton value="boolean">Yes / No</ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          )}

          {propertyType === "multi-select" && (
            <>
              <Typography variant="body2" component="label">
                Name your values
                <Box
                  component="span"
                  sx={{ color: "error.main", marginLeft: "2px" }}
                >
                  *
                </Box>
              </Typography>
              {fields &&
                fields.map((field, index) => (
                  <Box
                    key={field.id}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Controller
                      name={`propertyItems.${index}.value`}
                      control={control}
                      defaultValue={field.value || ""}
                      rules={{
                        required: "This field is required",
                        validate: (value) =>
                          value.trim() !== "" || "This field is required"
                      }}
                      render={({ field: controllerField }) => (
                        <TextField
                          {...controllerField}
                          placeholder="Ex: north, southwest, etc"
                          fullWidth
                          onChange={(e) => {
                            setValue(
                              `propertyItems.${index}.value`,
                              e.target.value
                            )
                            if (!field.valueId) {
                              setValue(
                                `propertyItems.${index}.valueId`,
                                field.id
                              )
                            }
                          }}
                          error={!!errors.propertyItems?.[index]?.value}
                          helperText={
                            errors.propertyItems?.[index]?.value?.message || ""
                          }
                          disabled={field?.isEditable === false}
                        />
                      )}
                    />
                    {field?.isEditable !== false && index > 0 && (
                      <IconButton onClick={() => remove(index)}>
                        <TrashOutline sx={{ height: "20px" }} />
                      </IconButton>
                    )}
                  </Box>
                ))}

              <Button
                variant="outlined"
                onClick={() => append({ value: "" })}
                sx={{ maxWidth: "187px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px"
                  }}
                >
                  <CircleAdd sx={{ height: "18px" }} />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Add another value
                  </Typography>
                </Box>
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          width: "48%",
          padding: "40px",
          boxSizing: "border-box",
          backgroundColor: "secondary.contrastText",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Box sx={{ width: "55%", textAlign: "center" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "secondary.dark"
            }}
          >
            You will be able to map each of your stores to one of these property
            values
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            width: "65%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "secondary.main",
            borderRadius: "8px",
            overflowY: "auto"
          }}
        >
          <Box sx={{ padding: "0 12px", height: "36px" }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: "700", lineHeight: "36px" }}
            >
              Your Custom Property
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "12px",
              borderTop: "1px solid",
              borderColor: "secondary.dark"
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "700" }}>
              {propertyName && propertyName.trim()
                ? propertyName
                : "Property Name"}
            </Typography>

            <RadioGroup
              key={propertyType}
              sx={{
                padding: "8px 16px",
                "& .MuiRadio-root": {
                  color: "secondary.dark"
                },
                "& .Mui-checked": {
                  color: "primary.dark"
                },
                "& .MuiTypography-root": {
                  fontSize: "14px"
                }
              }}
            >
              {propertyType === "boolean" ? (
                propertyItems &&
                propertyItems.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={<Radio />}
                    label={item.value}
                    sx={{ height: "32px" }}
                  />
                ))
              ) : (
                <>
                  {propertyItems &&
                    propertyItems.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Radio />}
                        label={item.value}
                        sx={{ height: "32px" }}
                      />
                    ))}

                  <FormControlLabel
                    value={notApplicable?.value}
                    control={<Radio />}
                    label={notApplicable?.value}
                    sx={{ height: "32px" }}
                  />
                </>
              )}
            </RadioGroup>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
    </Box>
  )
}

export default SetupStep
