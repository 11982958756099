import React from "react"
import { Box, Typography, MenuItem, FormControl, Select } from "@mui/material"
import { SelectChangeEvent } from "@mui/material/Select"
import { CampaignFormModel } from "../../../models/campaign"
import { useController, useFormContext } from "react-hook-form"
import { generateDurationList } from "../../../utils"

type ImagePlayProps = {
  onDurationChange: (newDuration: number) => Promise<void>
}

const ImagePlaySection = ({ onDurationChange }: ImagePlayProps) => {
  const { control, watch } = useFormContext<CampaignFormModel>()
  const { field: durationField } = useController({
    name: "file.duration",
    control
  })

  const asset = watch("file.asset")
  const storeArea = watch("storeArea")
  const currentCampaignId: number | undefined =
    Number(window.location.pathname.split("/")[2]) || undefined

  const isDisabled = !asset && typeof currentCampaignId === "number"

  const handleChange = (event: SelectChangeEvent) => {
    durationField.onChange(event.target.value)
    onDurationChange(Number(event.target.value))
  }

  const durationList = React.useMemo(() => {
    return generateDurationList(
      storeArea?.minCreativeDurationMs ?? 6000,
      storeArea?.maxCreativeDurationMs ?? 30000
    )
  }, [storeArea])

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ width: "192px", height: "36px", gap: "8px" }}
    >
      <Typography variant="body2">Duration:</Typography>
      <FormControl size="small" sx={{ flexGrow: 1 }}>
        <Select
          id="image-duration-select"
          size="small"
          displayEmpty
          value={durationField.value.toString()}
          onChange={handleChange}
          disabled={isDisabled}
          inputProps={{ "aria-label": "Without label" }}
        >
          {durationList.map((duration) => (
            <MenuItem key={duration} value={duration}>
              {duration} seconds
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ImagePlaySection
