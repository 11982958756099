import React from "react"
import { useNavigate } from "react-router-dom"
import { gql, useMutation } from "urql"
import { getInitials, getUserName } from "../../utils"
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Link
} from "@mui/material"

const loginQuery = gql`
  mutation Logout {
    logout
  }
`

interface Props {
  title?: string
}

const NavBar = (props: Props) => {
  const currentUserName = getUserName()
  const companyName = localStorage.getItem("companyName") || "Retailer"

  const navigate = useNavigate()
  const [, logout] = useMutation(loginQuery)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const userInitial = getInitials(currentUserName)

  const handleLogout = async () => {
    try {
      const result = await logout()
      if (result.data?.logout) {
        localStorage.clear()
        navigate("/")
        location.reload()
      }
    } catch (error) {
      console.error("Logout Error:", error)
    }
  }

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        padding: "0px 10%",
        backgroundImage: props.title
          ? "none"
          : "url(/grocery-tv-banner-logo.svg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.secondary.light}`
      }}
    >
      <Toolbar
        sx={{
          padding: 0,
          "&.MuiToolbar-root": {
            paddingLeft: 0,
            paddingRight: 0
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            height: "28px"
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "12px", fontWeight: 600 }}
          >
            {companyName}
          </Typography>
        </Box>
        <Box flexGrow={1} display="flex" justifyContent="space-around">
          <Typography>{props.title ? props.title : ""}</Typography>
        </Box>
        <IconButton onClick={handleClick}>
          <Avatar
            sx={{
              height: "28px",
              width: "28px",
              fontSize: "12px",
              fontWeight: 600,
              bgcolor: "avatar.main",
              cursor: "pointer"
            }}
          >
            {userInitial}
          </Avatar>
        </IconButton>
        <Menu
          id="nav-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: -2,
            horizontal: "right"
          }}
          MenuListProps={{
            disablePadding: true
          }}
          sx={{
            transform: "translateX(-3px)",
            "& .MuiPaper-root": {
              paddingY: "12px",
              borderRadius: "6px",
              border: "1px solid",
              borderColor: "primary.contrastText",
              width: "203px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
            },
            "& .MuiMenuItem-root": {
              padding: "8px 16px",
              fontSize: "14px"
            }
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/account")
            }}
          >
            Account
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/customproperty")
            }}
          >
            Custom Property
          </MenuItem>
          <MenuItem onClick={handleLogout}>Log out</MenuItem>
          <MenuItem onClick={handleClose}>
            <Link href="mailto:support@grocerytv.com" underline="none">
              <Box display="flex" flexDirection="column">
                <Typography
                  component="span"
                  sx={{ fontSize: "12px", color: "secondary.dark" }}
                >
                  Need help?
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "hint.main"
                    }
                  }}
                >
                  support@grocerytv.com
                </Typography>
              </Box>
            </Link>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
