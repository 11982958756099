import React from "react"
import {
  Box,
  Select,
  FormControl,
  Button,
  OutlinedInput,
  FormControlLabel,
  Divider
} from "@mui/material"

import CheckBox from "@mui/material/Checkbox"
import { StatusList } from "../../../models/campaign"
import SearchInput from "../../inputs/SearchInput"
import { useNavigate } from "react-router-dom"
import { StoreArea } from "../../../models/storearea"

interface Props {
  searchInput: string
  storeAreas: StoreArea[]
  checkedStatuses: string[]
  checkedStoreAreas: StoreArea[]
  handleSearchInput: React.ChangeEventHandler<HTMLInputElement>
  handleClearInput: () => void
  handleResetAll: () => void
  handleCheckAllStatus: (isChecked: boolean) => void
  handleStatusChange: (isChecked: boolean, statusName: string) => void
  handleStoreAreaChange: (isChecked: boolean, storeArea: StoreArea) => void
}

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  PaperProps: {
    style: {
      marginTop: "12px",
      width: "186px"
    }
  }
} as const

const CampaignListTableHeader = ({
  storeAreas,
  searchInput,
  checkedStatuses,
  checkedStoreAreas,
  handleSearchInput,
  handleClearInput,
  handleResetAll,
  handleCheckAllStatus,
  handleStatusChange,
  handleStoreAreaChange
}: Props) => {
  const [openStatus, setOpenStatus] = React.useState(false)
  const [openStoreArea, setOpenStoreArea] = React.useState(false)

  const filteredStatusList = StatusList.filter((name) => name !== "Draft")

  const showReset =
    !!checkedStatuses.length ||
    !!checkedStoreAreas.length ||
    !!searchInput.length

  const handleCloseStatus = () => {
    setOpenStatus(false)
  }

  const handleCloseStoreArea = () => {
    setOpenStoreArea(false)
  }

  const navigate = useNavigate()

  const filteredStoreAreas = storeAreas.filter(
    (storeArea) => storeArea.isInstalled === true
  )

  return (
    <Box display={"flex"} mt="8px" mb="16px" justifyContent={"space-between"}>
      <Box display={"flex"} gap="12px">
        <SearchInput
          componentId="campaign-search"
          searchInput={searchInput}
          onChange={handleSearchInput}
          onClear={handleClearInput}
        />

        <FormControl size="small">
          <Select
            size="small"
            id="campaign-status"
            open={openStatus}
            onOpen={() => setOpenStatus(true)}
            onClose={handleCloseStatus}
            multiple
            displayEmpty
            value={checkedStatuses}
            input={<OutlinedInput />}
            renderValue={(selected) =>
              selected.length > 0 ? `Status (${selected.length})` : "Status"
            }
            MenuProps={menuProps}
            inputProps={{
              "aria-label": "Without label"
            }}
            sx={{ minWidth: "86px", backgroundColor: "secondary.main" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              padding="8px"
            >
              <FormControlLabel
                control={
                  <CheckBox
                    checked={checkedStatuses.length === StatusList.length}
                    indeterminate={
                      checkedStatuses.length !== StatusList.length &&
                      checkedStatuses.length > 0
                    }
                    onChange={(event) => {
                      handleCheckAllStatus(event.target.checked)
                    }}
                    sx={{ color: "secondary.dark" }}
                  />
                }
                label="Select All"
                componentsProps={{ typography: { variant: "body2" } }}
              />

              {checkedStatuses &&
                filteredStatusList.map((statusName) => (
                  <FormControlLabel
                    control={
                      <CheckBox
                        checked={checkedStatuses.includes(statusName)}
                        onChange={(event) =>
                          handleStatusChange(event.target.checked, statusName)
                        }
                        sx={{ color: "secondary.dark" }}
                      />
                    }
                    key={statusName}
                    label={statusName}
                    componentsProps={{ typography: { variant: "body2" } }}
                  />
                ))}
            </Box>
            <Divider />

            <Box
              display={"flex"}
              justifyContent={"right"}
              padding="12px"
              mt="8px"
            >
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseStatus}
              >
                Close
              </Button>
            </Box>
          </Select>
        </FormControl>
        <FormControl size="small">
          <Select
            size="small"
            id="campaign-store-area"
            open={openStoreArea}
            onOpen={() => setOpenStoreArea(true)}
            onClose={handleCloseStoreArea}
            multiple
            displayEmpty
            value={checkedStoreAreas}
            input={<OutlinedInput />}
            renderValue={(selected) =>
              selected.length > 0
                ? `Store Area (${selected.length})`
                : "Store Area"
            }
            MenuProps={menuProps}
            inputProps={{ "aria-label": "Without label" }}
            sx={{ minWidth: "120px", backgroundColor: "secondary.main" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              padding="8px"
            >
              {filteredStoreAreas.map((storeArea) => (
                <FormControlLabel
                  control={
                    <CheckBox
                      checked={checkedStoreAreas.includes(storeArea)}
                      onChange={(event) =>
                        handleStoreAreaChange(event.target.checked, storeArea)
                      }
                      sx={{ color: "secondary.dark" }}
                    />
                  }
                  key={storeArea.storeAreaId}
                  label={storeArea.storeAreaName}
                  componentsProps={{ typography: { variant: "body2" } }}
                />
              ))}
            </Box>
            <Divider />
            <Box
              display={"flex"}
              justifyContent={"right"}
              padding="12px"
              mt="8px"
            >
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseStoreArea}
              >
                Close
              </Button>
            </Box>
          </Select>
        </FormControl>
        {showReset ? (
          <Button
            onClick={handleResetAll}
            sx={{ marginLeft: "auto", color: "text.secondary" }}
          >
            Reset all
          </Button>
        ) : (
          <></>
        )}
      </Box>

      <Button
        variant="contained"
        onClick={() => {
          navigate("/campaigns/create")
        }}
        sx={{ color: "secondary.main", boxShadow: "none" }}
      >
        Create Campaign
      </Button>
    </Box>
  )
}

export default CampaignListTableHeader
