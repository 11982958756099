import React from "react"
import { useController, useFormContext, Controller } from "react-hook-form"
import { Box, Switch, TextField, Tooltip, Typography } from "@mui/material"
import { Status } from "../../../models/campaign"
import DatePicker from "../../datepicker"
import ErrorBanner from "../../banners/ErrorBanner"
import dayjs from "dayjs"
import HoverIcon from "../../icons/HoverIcon"
import InfoOutlineIcon from "../../icons/InfoOutlineIcon"
import InfoIcon from "../../icons/InfoIcon"

type CampaignFormProps = {
  currentStartDate?: dayjs.Dayjs | undefined
  currentEndDate?: dayjs.Dayjs | undefined
  campaignStatus?: Status
}

const CampaignForm = ({
  currentStartDate,
  currentEndDate,
  campaignStatus
}: CampaignFormProps) => {
  const { control, formState, setError } = useFormContext()
  const { errors } = formState

  const { field: startDateField } = useController({
    name: "startDate",
    control,
    rules: {
      required: "Start date is required"
    }
  })

  const { field: endDateField } = useController({
    name: "endDate",
    control,
    rules: {
      required: "End date is required"
    }
  })

  const startDateDisabled = currentStartDate
    ? dayjs(currentStartDate).isBefore(dayjs(), "day")
    : false

  const endDateDisabled = currentEndDate
    ? dayjs(currentEndDate).isBefore(dayjs(), "day")
    : false

  const nameDisabled = campaignStatus === "Ended"

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Box display="flex" justifyContent="flex-start" gap="24px">
        <Box
          sx={{
            width: "385px"
          }}
        >
          <Typography
            variant="body2"
            component="label"
            display="flex"
            gap="2px"
            marginBottom="8px"
            color={nameDisabled ? "text.disabled" : "inherit"}
          >
            Name{" "}
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "error.main" }}
            >
              *
            </Typography>
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Name is required"
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                variant="outlined"
                type="text"
                placeholder="Campaign name"
                helperText={errors.name?.message as string}
                error={!!errors.name}
                disabled={nameDisabled}
                sx={{
                  width: "385px"
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            width: "100%"
          }}
          display="flex"
          gap="24px"
        >
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: "Start date is required"
              }}
              render={({ field }) => (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    gap="2px"
                    alignItems="center"
                    sx={{
                      margin: "0px",
                      padding: "0px",
                      marginBottom: "8px"
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="label"
                      display="flex"
                      gap="2px"
                      color={nameDisabled ? "text.disabled" : "inherit"}
                    >
                      Start date
                    </Typography>
                    <Tooltip
                      title="The campaign will begin playing on this date when the targeted stores open for business."
                      placement="right"
                      sx={{
                        padding: "0px",
                        margin: "0px"
                      }}
                    >
                      <span
                        style={{
                          margin: "0px",
                          padding: "0px",
                          lineHeight: "0px"
                        }}
                      >
                        <HoverIcon
                          hover={
                            <InfoIcon
                              sx={{
                                height: "16px",
                                width: "16px"
                              }}
                            />
                          }
                          normal={
                            <InfoOutlineIcon
                              sx={{
                                height: "16px",
                                width: "16px"
                              }}
                            />
                          }
                          sx={{
                            margin: "0px",
                            padding: "0px",
                            height: "16px",
                            width: "16px",
                            cursor: "pointer"
                          }}
                        />
                      </span>
                    </Tooltip>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ color: "error.main" }}
                    >
                      *
                    </Typography>
                  </Box>
                  <DatePicker
                    {...field}
                    disabled={startDateDisabled || campaignStatus === "Ended"}
                    label=""
                    value={field.value ? dayjs(field.value) : null}
                    minDate={dayjs()}
                    maxDate={endDateField.value}
                    onBlur={(event) => {
                      const newValue = event.target.value
                      if (dayjs(newValue).isBefore(dayjs(), "day")) {
                        setError("startDate", {
                          type: "manual",
                          message: "Invalid Start Date"
                        })
                      }
                    }}
                  />
                </>
              )}
            />
          </Box>

          <Box
            sx={{
              width: "100%"
            }}
          >
            <Controller
              name="endDate"
              control={control}
              rules={{
                required: "End date is required"
              }}
              render={({ field }) => (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    gap="2px"
                    alignItems="center"
                    sx={{
                      margin: "0px",
                      padding: "0px",
                      marginBottom: "8px"
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="label"
                      display="flex"
                      gap="2px"
                      color={nameDisabled ? "text.disabled" : "inherit"}
                    >
                      End date
                    </Typography>
                    <Tooltip
                      title="The campaign will stop playing on this date when the targeted stores close for business."
                      placement="right"
                      sx={{
                        padding: "0px",
                        margin: "0px"
                      }}
                    >
                      <span
                        style={{
                          margin: "0px",
                          padding: "0px",
                          lineHeight: "0px"
                        }}
                      >
                        <HoverIcon
                          hover={
                            <InfoIcon
                              sx={{
                                height: "16px",
                                width: "16px"
                              }}
                            />
                          }
                          normal={
                            <InfoOutlineIcon
                              sx={{
                                height: "16px",
                                width: "16px"
                              }}
                            />
                          }
                          sx={{
                            margin: "0px",
                            padding: "0px",
                            height: "16px",
                            width: "16px",
                            cursor: "pointer"
                          }}
                        />
                      </span>
                    </Tooltip>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ color: "error.main" }}
                    >
                      *
                    </Typography>
                  </Box>
                  <DatePicker
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    disabled={endDateDisabled || campaignStatus === "Ended"}
                    label=""
                    minDate={
                      dayjs(startDateField?.value).isAfter(dayjs(), "day")
                        ? startDateField?.value
                        : dayjs()
                    }
                    onBlur={(event) => {
                      const newValue = event.target.value
                      if (
                        dayjs(newValue).isBefore(dayjs(), "day") ||
                        (startDateField?.value &&
                          dayjs(newValue).isBefore(
                            startDateField?.value,
                            "day"
                          ))
                      ) {
                        setError("endDate", {
                          type: "manual",
                          message: "Invalid End Date"
                        })
                      }
                    }}
                  />
                </>
              )}
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
              No End Date
            </Typography>
            <Controller
              name="isNoEndDate"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked)
                  }}
                />
              )}
            />
          </Box>
        </Box>
        {errors.startDate?.message && (
          <ErrorBanner>{errors.startDate?.message as string}</ErrorBanner>
        )}
        {errors.endDate?.message && (
          <ErrorBanner>{errors.endDate?.message as string}</ErrorBanner>
        )}
      </Box>
    </Box>
  )
}

export default CampaignForm
