import React from "react"
import { Box, Typography, List, Modal, Button, ListItem } from "@mui/material"

type AssetModalProps = {
  open: boolean
  onClose: () => void
  orientation?: "16:9" | "9:16"
  durationMinmum?: number
  durationMaxmum?: number
}

const AssetGuidelinesModal = ({
  open,
  onClose,
  orientation,
  durationMinmum,
  durationMaxmum
}: AssetModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "space-around"
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          bgcolor: "secondary.main",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "12px",
          padding: "24px",
          width: "320px",
          overflowY: "auto"
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: "fileInput.dark",
            marginBottom: "30px"
          }}
        >
          {orientation === "16:9"
            ? "Landscape Asset Guidelines"
            : "Portrait Asset Guidelines"}
        </Typography>

        <Box>
          <List
            component="div"
            aria-labelledby="landscape-img-requirement"
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
              marginBottom: "30px",

              "& .MuiListItem-root": {
                fontSize: "14px",
                color: "text.primary",
                paddingLeft: "8px",
                paddingRight: "0px",
                paddingBottom: 0
              }
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
                color: "fileInput.light",

                paddingBottom: 0
              }}
            >
              Supported file types: png, jpg, mp4
            </ListItem>
            <ListItem
              sx={{
                display: "list-item"
              }}
            >
              Video duration: {(durationMinmum ?? 6000) / 1000}s to{" "}
              {(durationMaxmum ?? 30000) / 1000}s
            </ListItem>
            <ListItem
              sx={{
                display: "list-item"
              }}
            >
              Minimum resolution:{" "}
              {orientation === "16:9" ? "1920x1080 px" : "1080x1920 px"}
            </ListItem>

            <ListItem
              sx={{
                display: "list-item"
              }}
            >
              Max file size: 50mb
            </ListItem>
          </List>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{
              marginTop: "30px"
            }}
          >
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{
                height: "36px",
                width: "84px",
                color: "text.primary"
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default AssetGuidelinesModal
