import React, { useState, useEffect } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { Box } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import StepComponent from "../../customProperty/StepperComponent"
import SetupStep from "../../customProperty/SetupStep"
import MapStoresStep from "../../customProperty/MapStoresStep"
import ReviewStep from "../../customProperty/ReviewStep"
import NavBar from "../../navbar"
import Loader from "../../Loader"
import { StoreTargetMapping } from "../../../models/storetarget"
import { NewCampaignStore } from "../../../models/campaign"
import { http } from "../../App"
interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyItem {
  value: string
  valueId?: string
  isEditable?: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}

const ManageCustomProperty = () => {
  const { id } = useParams<{ id: string }>()
  const isEditMode = !!id
  const [stores, setStores] = useState<NewCampaignStore[]>([])
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [property, setProperty] = useState<PropertyApiResponse | undefined>(
    undefined
  )

  const compareNameFn = () => {
    return (itemA: NewCampaignStore, itemB: NewCampaignStore) =>
      itemA.name < itemB.name ? -1 : 1
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true)

      try {
        // Always fetch stores
        const storesRes = await http.get(
          `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/stores`,
          {
            withCredentials: true
          }
        )
        setStores(storesRes.data.sort(compareNameFn()))

        // Conditionally fetch store properties in edit mode
        if (isEditMode) {
          const propertyRes = await http.get(
            `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/store_properties/${id}`,
            {
              withCredentials: true
            }
          )
          const propertyData = propertyRes.data
          setProperty(propertyData)
        }
      } catch (error) {
        console.error("Failed to fetch data:", error)
      } finally {
        setIsLoadingData(false)
      }
    }

    fetchData()
  }, [isEditMode])

  const methods = useForm({
    mode: "all"
  })
  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const [setupComplete, setSetupComplete] = useState(false)
  const [mapStoresComplete, setMapStoresComplete] = useState(false)
  const [reviewComplete, setReviewComplete] = useState(false)

  const steps = [
    { label: "Setup", completed: setupComplete },
    { label: "Map Stores", completed: mapStoresComplete },
    { label: "Review", completed: reviewComplete }
  ]

  const handleNext = async () => {
    if (activeStep < steps.length - 1) {
      const nextStep = activeStep + 1

      if (nextStep === steps.length - 1) {
        setReviewComplete(true)
      }

      setActiveStep(nextStep)
    } else {
      try {
        setLoading(true)

        const formValues = methods.getValues()
        const storeTargetMappings: StoreTargetMapping[] =
          formValues.StoreTargetMappings
        const propertyName = formValues.propertyName as string
        const propertyType = formValues.propertyType as string
        const propertyItems = formValues.propertyItems as PropertyItem[]

        if (isEditMode) {
          // Compare property name

          if (property && propertyName !== property.name) {
            const propertyResponse = await http.put(
              `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/store_properties/${property.id}`,
              { name: propertyName },
              { withCredentials: true }
            )
            setProperty(propertyResponse.data)
          }

          if (propertyType === "multi-select" && property) {
            const apiPropertyValues = property.values

            const deletePropertyValuePromises: Promise<void>[] = []
            const putPropertyValuePromises: Promise<void>[] = []
            const postPropertyValuePromises: Promise<void>[] = []

            // Create a set of value IDs from the form
            const formValueIds = new Set(
              propertyItems.map((item) => item.valueId)
            )
            const notApplicableItem = formValues.notApplicable
            if (notApplicableItem) {
              formValueIds.add(notApplicableItem.valueId)
            }

            // Check for deleted items
            apiPropertyValues.forEach((apiValue) => {
              if (!formValueIds.has(apiValue.id.toString())) {
                deletePropertyValuePromises.push(
                  http.delete(
                    `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/store_properties/${property.id}/values/${apiValue.id}`,
                    { withCredentials: true }
                  )
                )
              }
            })

            // Check for new and updated items
            for (const item of propertyItems) {
              const valueId = item.valueId
              const valueName = item.value

              const isPropertyValueExist = apiPropertyValues.find(
                (val) => val.id.toString() === valueId
              )

              if (isNaN(Number(valueId)) && !isPropertyValueExist) {
                // New item, add it
                postPropertyValuePromises.push(
                  http.post(
                    `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/store_properties/${property.id}/values`,
                    { name: valueName },
                    { withCredentials: true }
                  )
                )
              } else if (
                isPropertyValueExist &&
                isPropertyValueExist.name !== valueName
              ) {
                // Item updated, modify it
                putPropertyValuePromises.push(
                  http.put(
                    `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/store_properties/${property.id}/values/${valueId}`,
                    { name: valueName },
                    { withCredentials: true }
                  )
                )
              }
            }

            // Execute all delete, put, and post operations concurrently
            await Promise.all(deletePropertyValuePromises)
            await Promise.all(putPropertyValuePromises)
            await Promise.all(postPropertyValuePromises)
          }

          // Fetch the latest property data
          const updatedPropertyResponse = await http.get(
            `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/store_properties/${property?.id}`,
            { withCredentials: true }
          )
          const updatedProperty: PropertyApiResponse =
            updatedPropertyResponse.data

          // Fetch the latest store data
          const storesRes = await http.get(
            `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/stores`,
            { withCredentials: true }
          )
          const updatedStores: NewCampaignStore[] = storesRes.data.sort(
            compareNameFn()
          )

          // Normalize the store data
          const normalizedStoreData = updatedStores
            .map((store) => {
              return store.properties.map((property) => ({
                storesId: store.id,
                propertyValueId: property.value_id.toString(),
                propertyValue: property.value_name
              }))
            })
            .flat()

          // Check if store associations have changed
          const hasAssociationsChanged = storeTargetMappings.some((mapping) => {
            const storeProperty = normalizedStoreData.find(
              (data) =>
                data.storesId === mapping.storesId &&
                data.propertyValueId === mapping.propertyValueId
            )

            return (
              !storeProperty ||
              storeProperty.propertyValue !== mapping.propertyValue
            )
          })

          if (hasAssociationsChanged) {
            // Delete all existing associations
            await Promise.all(
              updatedStores.map(async (store) => {
                const storeProperties = store.properties.filter(
                  (prop) => prop.property_id === property?.id
                )
                await Promise.all(
                  storeProperties.map(async (storeProperty) => {
                    await http.delete(
                      `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/stores/${store.id}/store_property_values/${storeProperty.value_id}`,
                      { withCredentials: true }
                    )
                  })
                )
              })
            )

            // Create new associations

            await Promise.all(
              storeTargetMappings.map(async (mapping) => {
                const foundValue = updatedProperty?.values.find(
                  (val) => val.name === mapping.propertyValue
                )

                if (foundValue) {
                  await http.post(
                    `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/stores/${mapping.storesId}/store_property_values/${foundValue.id}`,
                    {},
                    { withCredentials: true }
                  )
                } else {
                  console.error(
                    `Value not found for property value: ${mapping.propertyValue}`
                  )
                }
              })
            )
          }
        } else {
          // Send the property creation request to the backend
          const propertyResponse = await http.post(
            `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/store_properties`,
            {
              name: propertyName,
              type: propertyType,
              values: propertyItems.map((item) => item.value)
            },
            { withCredentials: true }
          )

          const propertyData = propertyResponse.data as PropertyApiResponse

          // Map store property values to the backend
          await Promise.all(
            storeTargetMappings.map(async (mapping) => {
              const foundValue = propertyData.values.find(
                (val) => val.name === mapping.propertyValue
              )

              if (foundValue) {
                await http.post(
                  `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/stores/${mapping.storesId}/store_property_values/${foundValue.id}`,
                  {},
                  { withCredentials: true }
                )
              } else {
                console.error(
                  `Value not found for property value: ${mapping.propertyValue}`
                )
              }
            })
          )
        }

        console.log("Store property values mapped successfully.")
        navigate("/customproperty")
      } catch (error) {
        console.error("Error submitting form:", error)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/customproperty")
    } else {
      setActiveStep(activeStep - 1)
    }
  }

  return (
    <Box sx={{ overflowY: "hidden" }}>
      <NavBar title="Create Store Property" />
      <Box
        sx={{
          padding: "40px 128px 112px 128px",
          display: "flex",
          marginTop: "64px",
          overflowY: "hidden",
          flexDirection: "column"
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleNext)}>
            <StepComponent
              steps={steps}
              activeStep={activeStep}
              isEditMode={isEditMode}
              onNext={handleNext}
              onBack={handleBack}
              onSubmit={methods.handleSubmit(handleNext)}
              stepBody={
                <>
                  {activeStep === 0 && (
                    <SetupStep
                      setStepComplete={setSetupComplete}
                      isEditMode={isEditMode}
                      property={property}
                    />
                  )}
                  {activeStep === 1 && (
                    <MapStoresStep
                      setMapStoresComplete={setMapStoresComplete}
                      stores={stores}
                      isEditMode={isEditMode}
                    />
                  )}
                  {activeStep === 2 && <ReviewStep />}
                </>
              }
            />
          </form>
        </FormProvider>
      </Box>
      <Loader
        active={loading || isLoadingData}
        title="Submitting"
        content="Please wait..."
      />
    </Box>
  )
}

export default ManageCustomProperty
