import React from "react"
import { Box, Typography } from "@mui/material"
import ArrowDown from "../../icons/ArrowDown"
import ArrowUp from "../../icons/ArrowUp"

interface HeaderRowProps {
  columns: string[]
  onSortChange: (columnName: string) => void
  sortTargetsByColumn: string
  sortTargetsDirection: "asc" | "desc"
}

const HeaderRow = ({
  columns,
  onSortChange,
  sortTargetsByColumn,
  sortTargetsDirection
}: HeaderRowProps) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: columns.length == 2 ? "1fr 1fr" : "1.5fr 1.5fr 1fr",
      padding: "2px 0",
      backgroundColor: "secondary.main",
      fontWeight: "bold"
    }}
  >
    {columns.map((column) => (
      <Box
        key={column}
        sx={{
          display: "flex",
          justifyContent: "start",
          padding: "0 12px",
          alignItems: "center",
          cursor: "pointer"
        }}
        gap="2px"
        onClick={() => onSortChange(column)}
      >
        <Typography
          sx={{ lineHeight: "32px", fontSize: "12px", fontWeight: 600 }}
          variant="body2"
        >
          {column}
        </Typography>
        {sortTargetsByColumn === column &&
          (sortTargetsDirection === "asc" ? (
            <ArrowUp
              sx={{
                height: "14px",
                width: "14px"
              }}
            />
          ) : (
            <ArrowDown
              sx={{
                height: "14px",
                width: "14px"
              }}
            />
          ))}
      </Box>
    ))}
  </Box>
)

export default HeaderRow
