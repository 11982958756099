import React from "react"
import { TableRow, TableCell, Checkbox } from "@mui/material"
import { StoreArea } from "../../../models/storearea"

interface Props {
  storeArea: StoreArea
  onSelectStore: (storeArea: StoreArea) => void
  isSelected: boolean
  disabled?: boolean
}

const StoreAreaDisplay = ({
  storeArea,
  onSelectStore,
  isSelected,
  disabled
}: Props) => {
  const handleCheckboxChange = () => {
    if (!disabled) {
      onSelectStore(storeArea)
    }
  }
  return (
    <TableRow
      sx={{ height: "36px", "&:last-child td": { borderBottom: "none" } }}
      onClick={handleCheckboxChange}
    >
      <TableCell sx={{ padding: 0 }}>
        <Checkbox
          checked={isSelected}
          sx={{
            color: "secondary.dark",
            height: "36px",
            width: "36px",
            borderRadius: "50%",

            "&.Mui-checked": {
              color: "primary.light"
            }
          }}
        />
        {storeArea.storeAreaName}
      </TableCell>
      <TableCell align="right" sx={{ padding: 0 }}></TableCell>
    </TableRow>
  )
}

export default StoreAreaDisplay
