/* eslint-disable indent */
import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { isEmpty } from "lodash"
import { Box, Link, Typography } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import * as amplitude from "@amplitude/analytics-browser"
import PQueue from "p-queue"
import { FormProvider, useController, useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import { ScreenLocation, ScreenLocationState } from "src/models/screenlocation"
import { gql, useMutation, useQuery } from "urql"
import debounce from "lodash/debounce"
import {
  Campaign,
  NewCampaignStore,
  CampaignFormModel,
  CampaignFormSchema,
  FieldsType,
  FileErrorType,
  Status
} from "../../../models/campaign"

import { StoreArea } from "../../../models/storearea"
import { getUserId } from "../../../utils"
import { http } from "../../App"
import DiscardBanner from "../../banners/DiscardBanner"
import ErrorBanner from "../../banners/ErrorBanner"
import SuccessChangeBanner from "../../banners/SuccessChangeBanner"
import WarningBanner from "../../banners/WarningBanner"
import CampaignCreative from "../../campaigns/CampaignCreative"
import CampaignForm from "../../campaigns/CampaignForm"
import ConfirmationModal from "../../campaigns/ConfirmationModal"
import {
  AssetAspectError,
  AssetAspectWithStoreError,
  AssetResolutionError,
  AssetTypeError,
  VideoDurationError
} from "../../campaigns/ErrorMessage"
import StoreAreaSelector from "../../campaigns/StoreAreaSelector"
import TargetSelector from "../../campaigns/TargetSelector"
import ArrowLeft from "../../icons/ArrowLeft"
import Loader from "../../Loader"
import ScreenLocationSelector from "../../ScreenLocationSelector/index"
import StatusBadge from "../../StatusBadge"
import EditCampaignNavBar from "../../EditCampaignNavBar"

type FileErrorProps = {
  name: string
}

type FileError = {
  type: FileErrorType
  fileName: string
}

type UploadResponseState = {
  id: number
  duration: number
} | null

const errorComponentMap: Record<
  FileErrorType,
  React.ComponentType<FileErrorProps>
> = {
  "unsupported-file-type": AssetTypeError,
  "invalid-video-duration": VideoDurationError,
  "invalid-asset-aspect-ratio": AssetAspectError,
  "invalid-asset-aspect-ratio-for-store": AssetAspectWithStoreError,
  "invalid-asset-resolution": AssetResolutionError
}

type GetCampaignQuery = {
  campaignDetails: Campaign
}

const DATE_FORMAT = "YYYY-MM-DD"

const UPLOAD_CAMPAIGN_VIDEO_MUTATION = gql`
  mutation UploadCampaignVideo($fields: CreateVideoInput!, $file: Upload!) {
    uploadCampaignVideo(fields: $fields, file: $file) {
      id
      duration
    }
  }
`
const SAVE_CAMPAIGN_MUTATION = gql`
  mutation saveCampaign($fields: CampaignInput!) {
    saveCampaignDetails(fields: $fields) {
      id
      status
      isGlobal
      name
      campaignTypesId
      campaignCategoriesId
      videosId
      tmpVideo
      duration
      activeStores {
        name
        id
        impressions
      }
      draftStores {
        name
        id
      }
    }
  }
`

const STORE_FRAGMENT = gql`
  fragment StoreArgs on Store {
    storeId: id
    name
    deviceCount
    state
    city
    county
    address
  }
`

const GET_CAMPAIGN_VIDEO_URL = gql`
  query getVideoUrl($key: String!) {
    s3Url(key: $key)
  }
`

const GET_BANNERS = gql`
  query getBanners {
    banners {
      id
      name
      stores {
        ...StoreArgs
      }
    }
  }
  ${STORE_FRAGMENT}
`

const CURRENT_USER_QUERY = gql`
  query getUser($id: Int!) {
    userDetails(id: $id) {
      companiesId
      email
      id
    }
  }
`

const GET_CAMPAIGN_QUERY = gql`
  query campaignDetails($id: Int!) {
    campaignDetails(id: $id) {
      id
      campaignTypesId
      companiesId
      isGlobal
      duration
      endDate
      startDate
      status
      name
      impressions
      tmpVideo
      tmpVideoDuration
      creativeAspectRatio
      targetImpressions
      video {
        id
        name
        url
        duration
      }
      activeStores {
        id
        name
      }
      storeAreasId
    }
  }
`

interface Props {
  onDidSuccessfullyPublishCampaign: (campaign: Campaign) => void
  campaignId?: number
  campaignStatus?: Status
  campaignStoreAreas?: StoreArea[]
}

interface Params {
  "zone_ids[]"?: number
  "property_values[]"?: number[]
}

interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}

const uploadFileQueue = new PQueue({ concurrency: 1 })

const EditCampaign = ({
  campaignId,
  campaignStatus,
  onDidSuccessfullyPublishCampaign,
  campaignStoreAreas
}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location?.pathname
  const [openModal, setOpenModal] = React.useState(false)
  const [fileError, setFileError] = React.useState<FileError | null>(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isLoadingStores, setIsLoadingStores] = React.useState(false)
  const [stores, setStores] = React.useState<NewCampaignStore[]>()
  const [properties, setProperties] = React.useState<PropertyApiResponse[]>()
  const [storeLoadingError, setStoreLoadingError] =
    React.useState<Error | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [failedToUploadAsset, setFailedToUploadAsset] = React.useState(false)
  const [failedToPublish, setFailedToPublish] = React.useState(false)
  const [discardUnSavedChange, setDiscardUnSavedChange] = React.useState(false)
  const [publishChange, setPublishChange] = React.useState(false)
  const [fileUploadResponse, setFileUploadResponse] =
    React.useState<UploadResponseState>(null)
  const [pendingUploads, setPendingUploads] = React.useState(false)

  const [showBoxShadow, setShowBoxShadow] = React.useState(false)
  const [screenLocations, setScreenLocations] = React.useState<
    ScreenLocation[]
  >([])

  const formMethods = useForm<CampaignFormModel>({
    resolver: yupResolver(CampaignFormSchema),
    mode: "all",
    defaultValues: {
      name: "",
      startDate: undefined,
      endDate: undefined,
      storeArea: undefined,
      storeSearchTerm: undefined,
      activeStores: [],
      isNoEndDate: false,
      file: {},
      selectedPropertyValues: []
    }
  })

  const {
    register,
    control,
    setError,
    setValue,
    formState,
    watch,
    resetField,
    reset
  } = formMethods

  register("activeStores")
  useController({ name: "activeStores", control: control })

  const formValues = watch()
  const storeArea = watch("storeArea")
  const selectedPropertyValues = watch("selectedPropertyValues")

  const assetId = watch("file.assetId")
  const userId = getUserId()
  const [{ data: currentUser }] = useQuery({
    query: CURRENT_USER_QUERY,
    pause: !userId,
    variables: {
      id: userId
    }
  })

  const [{ data: campaignVideo }] = useQuery({
    query: GET_CAMPAIGN_VIDEO_URL,
    pause: !assetId,
    variables: {
      key: `videos/${assetId}/${assetId}.timg1.png`
    }
  })

  const [
    {
      data: campaignData,
      fetching: campaignFetching,
      error: campaignFetchError
    }
  ] = useQuery<GetCampaignQuery>({
    query: GET_CAMPAIGN_QUERY,
    pause: !campaignId,
    variables: {
      id: campaignId
    }
  })

  const fetchStores = (storeAreaId: number, propertyValues?: number[]) => {
    const params: Params = {
      "zone_ids[]": storeAreaId
    }

    if (propertyValues && propertyValues.length > 0) {
      params["property_values[]"] = selectedPropertyValues?.filter(
        (value): value is number => value !== undefined
      )
    }

    return http.get(`${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/stores`, {
      params,
      withCredentials: true
    })
  }

  const fetchProperties = async () => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_STORE_PROPERTY_API_PATH}/store_properties`,
        {
          withCredentials: true
        }
      )
      setProperties(response.data)
    } catch (error) {
      console.error("Failed to fetch properties:", error)
    }
  }

  React.useEffect(() => {
    const handleScroll = () => {
      const dialogContent = document.querySelector(
        ".MuiDialog-root .MuiPaper-root"
      )
      if (dialogContent) {
        const hasScrolled = dialogContent.scrollTop > 24
        setShowBoxShadow(hasScrolled)
      }
    }

    const timer = setTimeout(() => {
      const dialogContent = document.querySelector(
        ".MuiDialog-root .MuiPaper-root"
      )
      if (dialogContent) {
        dialogContent.addEventListener("scroll", handleScroll)
      }
    }, 100)

    return () => {
      const dialogContent = document.querySelector(
        ".MuiDialog-root .MuiPaper-root"
      )
      if (dialogContent) {
        dialogContent.removeEventListener("scroll", handleScroll)
      }
      clearTimeout(timer)
    }
  }, [])

  React.useEffect(() => {
    fetchProperties()
  }, [])

  const getScreenLocationsByStoreArea = React.useMemo(() => {
    if (storeArea) {
      return screenLocations.filter(
        (screenLocation) =>
          screenLocation.store_area.id === storeArea.storeAreaId
      )
    }
    return []
  }, [storeArea, screenLocations])

  const selectedScreenLocation = watch("selectedScreenLocation")

  const filteredStores = React.useMemo(() => {
    if (storeArea) {
      const filtered = stores ?? []
      if (storeArea.hasScreenLevelTargeting) {
        const screenLocationsByStore = screenLocations
          .filter(
            (screenLocation) =>
              screenLocation.store_area.id === storeArea.storeAreaId
          )
          .reduce((screenLocationsByStore, screenLocation) => {
            if (!screenLocationsByStore.has(screenLocation.store.id)) {
              screenLocationsByStore.set(screenLocation.store.id, [])
            }
            screenLocationsByStore
              .get(screenLocation.store.id)
              ?.push(screenLocation)
            return screenLocationsByStore
          }, new Map<number, ScreenLocation[]>())

        const selectedScreenLocationSet = new Set<string | undefined>(
          selectedScreenLocation
        )
        return filtered.filter(
          (store) =>
            screenLocationsByStore
              .get(store.id)
              ?.some((screenLocation) =>
                selectedScreenLocationSet.has(screenLocation.description ?? "")
              )
        )
      }
      return filtered
    }
    return []
  }, [storeArea, stores, screenLocations, selectedScreenLocation])

  const activeStores = watch("activeStores")

  React.useEffect(() => {
    if (storeArea?.hasScreenLevelTargeting) {
      if (filteredStores.length === 0 && (activeStores?.length ?? 0) > 0) {
        resetField("activeStores", { defaultValue: [] })
      } else {
        const filteredActiveStores =
          activeStores?.filter(
            (activeStore) =>
              filteredStores.findIndex((store) => store.id === activeStore) !==
              -1
          ) ?? []
        if (
          filteredActiveStores !== undefined &&
          (activeStores?.length ?? 0) !== filteredActiveStores.length
        ) {
          reset({
            ...formMethods.getValues(),
            activeStores: filteredActiveStores
          })
        }
      }
    }
  }, [filteredStores, activeStores, storeArea])

  const [
    { data: bannersData, fetching: bannersFetching, error: bannersFetchError }
  ] = useQuery({
    query: GET_BANNERS
  })

  const [, uploadCampaignVideo] = useMutation(UPLOAD_CAMPAIGN_VIDEO_MUTATION)

  const [, saveCampaign] = useMutation(SAVE_CAMPAIGN_MUTATION)

  const compareNameFn = () => {
    return (itemA: NewCampaignStore, itemB: NewCampaignStore) =>
      itemA.name < itemB.name ? -1 : 1
  }

  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    if (
      !campaignFetching &&
      campaignFetchError === undefined &&
      !bannersFetching &&
      bannersFetchError === undefined &&
      stores &&
      campaignData &&
      campaignStoreAreas &&
      !initialized
    ) {
      setInitialized(true)
      if (campaignData.campaignDetails?.tmpVideo) {
        resetField("file", {
          defaultValue: {
            assetId: campaignData.campaignDetails.tmpVideo,
            assetType: "",
            assetUrl: "",
            duration: campaignData?.campaignDetails.duration / 1000
          }
        })
      } else if (campaignData.campaignDetails?.video?.id) {
        resetField("file", {
          defaultValue: {
            assetId: campaignData?.campaignDetails?.video.id,
            assetType: "",
            assetUrl: "",
            duration: campaignData?.campaignDetails.duration / 1000
          }
        })
      }
      if (campaignData.campaignDetails) {
        const selectedStoreArea = campaignStoreAreas?.find(
          (stores) =>
            stores.storeAreaId === campaignData.campaignDetails?.storeAreasId
        )

        resetField("name", { defaultValue: campaignData.campaignDetails.name })
        resetField("endDate", {
          defaultValue: dayjs(Number(campaignData.campaignDetails.endDate))
        })
        resetField("startDate", {
          defaultValue: dayjs(Number(campaignData.campaignDetails.startDate))
        })

        if (selectedStoreArea) {
          reset({
            ...formMethods.getValues(),
            storeArea: selectedStoreArea
          })
        }
      }

      const storeAreaId = campaignData.campaignDetails.storeAreasId

      const hasScreenLevelTargeting =
        campaignStoreAreas.find(
          (storeArea) => storeArea.storeAreaId === storeAreaId
        )?.hasScreenLevelTargeting ?? false

      const fetchScreenLocationsState = http.get(
        `${process.env.REACT_APP_REST_API_PATH}/v2/campaigns/${campaignId}/targets`,
        { withCredentials: true }
      )

      if (hasScreenLevelTargeting) {
        fetchScreenLocationsState
          .then((res: { data: ScreenLocationState }) => {
            const seenDescriptions = new Set<string>()
            const selectedScreenLocations = (res?.data?.screen_locations ?? [])
              .map((screenLocation) => screenLocation.description)
              .filter((description) => {
                if (seenDescriptions.has(description)) {
                  return false
                }
                seenDescriptions.add(description)
                return true
              })
            resetField("selectedScreenLocation", {
              defaultValue: selectedScreenLocations
            })

            const seenStore = new Set<number>()
            const activeStores = (res?.data?.screen_locations ?? [])
              // finding location
              .map(({ id }) =>
                screenLocations.find(
                  (screenLocation) => screenLocation.id === id
                )
              )
              // ignoring locations that were not found
              .filter(
                (screenLocation): screenLocation is ScreenLocation =>
                  screenLocation !== undefined
              )
              .map((screenLocation) => screenLocation.store.id)
              .filter((storeId) => {
                if (seenStore.has(storeId)) {
                  return false
                }
                seenStore.add(storeId)
                return true
              })
              .map((storeId) =>
                stores.find((store) => store.id === Number(storeId))
              )
              .filter((store): store is NewCampaignStore => store !== undefined)

            reset({
              ...formMethods.getValues(),
              activeStores: activeStores.map((store) => store.id)
            })
          })
          .catch((error) => {
            setStoreLoadingError(error)
          })
          .finally(() => setIsLoadingStores(false))
      } else {
        const activeStoreIds = campaignData.campaignDetails.activeStores.map(
          (store) => store.id
        )

        reset({
          ...formMethods.getValues(),
          activeStores: [...activeStoreIds]
        })
        setIsLoadingStores(false)
      }
    }
  }, [
    campaignData,
    campaignFetchError,
    campaignFetching,
    stores,
    storeArea,
    campaignStoreAreas,
    initialized
  ])

  React.useEffect(() => {
    if (campaignVideo?.s3Url) {
      if (formState.defaultValues?.file?.assetId === formValues.file.assetId) {
        resetField("file", {
          defaultValue: {
            ...formValues.file,
            assetUrl: campaignVideo.s3Url,
            assetType: "image/png"
          }
        })
      } else {
        setValue("file.assetUrl", campaignVideo.s3Url)
        setValue("file.assetType", "image/png")
      }
    }
  }, [campaignVideo])

  React.useEffect(() => {
    if (campaignId && campaignData) {
      if (!storeArea && campaignData.campaignDetails?.storeAreasId) {
        debouncedFetchStores(campaignData.campaignDetails.storeAreasId, [])
      } else if (
        campaignData.campaignDetails?.storeAreasId &&
        selectedPropertyValues &&
        selectedPropertyValues.length > 0
      ) {
        debouncedFetchStores(
          campaignData.campaignDetails.storeAreasId,
          selectedPropertyValues
        )
      }
    } else if (storeArea?.storeAreaId) {
      if (selectedPropertyValues && selectedPropertyValues.length > 0) {
        debouncedFetchStores(storeArea.storeAreaId, selectedPropertyValues)
      } else {
        debouncedFetchStores(storeArea.storeAreaId, [])
      }
    }
  }, [storeArea, campaignId, campaignData, selectedPropertyValues])

  const debouncedFetchStores = debounce(
    (storeAreaId, selectedPropertyValues = []) => {
      if (storeAreaId) {
        setIsLoadingStores(true)
        setStores([])

        // Filter out undefined values from selectedPropertyValues
        const validPropertyValues: number[] = selectedPropertyValues.filter(
          (value: number | undefined): value is number => value !== undefined
        )

        const fetchStoresPromise = fetchStores(
          storeAreaId,
          validPropertyValues.length > 0 ? validPropertyValues : []
        )

        const fetchScreenLocationsPromise = http.get(
          `${process.env.REACT_APP_REST_API_PATH}/v2/screen_locations`,
          {
            withCredentials: true
          }
        )

        Promise.all([fetchStoresPromise, fetchScreenLocationsPromise])
          .then(([storesRes, screenLocationsRes]) => {
            setStores(storesRes.data.sort(compareNameFn()))
            setScreenLocations(screenLocationsRes.data)
          })
          .catch((error) => {
            setStoreLoadingError(error)
          })
          .finally(() => setIsLoadingStores(false))
      }
    },
    300
  )

  const currentStartTimestamp = campaignData?.campaignDetails?.startDate
  const currentStartDate = currentStartTimestamp
    ? dayjs(Number(currentStartTimestamp))
    : undefined

  const currentEndTimestamp = campaignData?.campaignDetails?.endDate
  const currentEndDate = currentEndTimestamp
    ? dayjs(Number(currentEndTimestamp))
    : undefined

  const isSubmitDisabled = React.useMemo(
    () => !isEmpty(formState.errors) || !formState.isValid || isSubmitting,
    [formState, isSubmitting]
  )

  React.useEffect(() => {
    if (path === "/campaigns/create") {
      const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
        if (formState.isDirty && !openModal) {
          e.preventDefault()
          e.returnValue = ""
        }
      }

      window.addEventListener("beforeunload", beforeUnloadHandler)

      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler)
      }
    }
  }, [formState, openModal, path])

  const isCancelDisabled = React.useMemo(
    () => campaignId !== undefined && !formState.isDirty,
    [formState]
  )

  const handleFileError = (
    fileErrorType: FileErrorType | "clear-error",
    fileName: string
  ) => {
    if (fileErrorType === "clear-error") {
      setFileError(null)
      return
    } else {
      setFileError({ type: fileErrorType, fileName })
    }
  }

  React.useEffect(() => {
    uploadFileQueue.on("add", () => {
      setPendingUploads(true)
    })

    uploadFileQueue.on("idle", () => {
      setPendingUploads(false)
    })
  }, [])

  React.useEffect(() => {
    if (localStorage.getItem("discardChange") === "true") {
      setDiscardUnSavedChange(true)
      localStorage.removeItem("discardChange")

      setTimeout(() => {
        setDiscardUnSavedChange(false)
      }, 5000)
    }
  }, [discardUnSavedChange])

  React.useEffect(() => {
    if (localStorage.getItem("PublishChange") === "true") {
      setPublishChange(true)
      localStorage.removeItem("PublishChange")

      setTimeout(() => {
        setPublishChange(false)
      }, 5000)
    }
  }, [publishChange])

  React.useEffect(() => {
    const handleAutoSubmission = async () => {
      const bannerId = bannersData?.banners?.[0]?.id ?? null
      const isUpdateOperation = !!campaignId

      if (
        fileUploadResponse &&
        !failedToUploadAsset &&
        isSubmitting &&
        !pendingUploads
      ) {
        try {
          await handleCampaignSubmission(
            isUpdateOperation,
            formValues,
            bannerId,
            fileUploadResponse.id,
            Math.floor(fileUploadResponse.duration / 1000)
          )
        } catch (error) {
          console.error(error)
        } finally {
          setIsSubmitting(false)
          setLoading(false)
        }
      } else if (
        !fileUploadResponse &&
        isSubmitting &&
        !pendingUploads &&
        isUpdateOperation
      ) {
        try {
          await handleCampaignSubmission(
            isUpdateOperation,
            formValues,
            bannerId
          )
        } catch (error) {
          console.error(error)
        } finally {
          setIsSubmitting(false)
          setLoading(false)
        }
      }
    }

    handleAutoSubmission()
  }, [
    fileUploadResponse,
    isSubmitting,
    pendingUploads,
    bannersData,
    failedToUploadAsset
  ])

  const handleFileUpload = async (
    name: string,
    aspectRatio: string,
    type: string,
    asset: File,
    duration?: number
  ) => {
    try {
      const fields: FieldsType = {
        isTemplate: false,
        name: name,
        aspectRatio: aspectRatio,
        storeAreasId: formValues.storeArea?.storeAreaId
      }

      if (!type.startsWith("video/")) {
        fields.duration = duration
      }

      const uploadResponse = await uploadCampaignVideo({
        fields,
        file: asset
      })

      if (uploadResponse.error?.message) {
        setError("file", {
          type: "server",
          message: uploadResponse.error?.message.replace("[GraphQL]", "").trim()
        })
        setValue("file", {
          assetUrl: "",
          assetType: "",
          duration: 0,
          assetId: null
        })
        setFailedToUploadAsset(true)

        if (isSubmitting) {
          setIsSubmitting(false)
          setLoading(false)
        }
        return null
      } else {
        setFileUploadResponse({
          id: uploadResponse.data.uploadCampaignVideo.id,
          duration: Math.floor(
            uploadResponse.data.uploadCampaignVideo.duration / 1000
          )
        })
        return uploadResponse.data
      }
    } catch (error) {
      console.error(error)
      setFailedToUploadAsset(true)
    }
  }

  const handleCampaignSubmission = async (
    isUpdateOperation: boolean,
    data: CampaignFormModel,
    bannerId: number,
    assetId?: number,
    duration?: number
  ) => {
    let selection: (
      | {
          screenLocationId: number
          type: "screen_location"
          bannerId: number
        }
      | { storeId: number; type: "store"; bannerId: number }
    )[]
    if (storeArea?.hasScreenLevelTargeting) {
      selection = (data.activeStores ?? [])
        .flatMap((store) =>
          screenLocations.filter(
            (screenLocation) =>
              screenLocation.store_area.id === storeArea?.storeAreaId &&
              screenLocation.store.id === store &&
              data.selectedScreenLocation?.includes(
                screenLocation.description ?? ""
              )
          )
        )
        .filter(
          (screenLocation): screenLocation is ScreenLocation =>
            screenLocation !== undefined
        )
        .map((screenLocation) => ({
          screenLocationId: screenLocation.id,
          type: "screen_location",
          bannerId
        }))
    } else {
      selection = (data.activeStores ?? []).map((store) => ({
        storeId: store,
        type: "store",
        bannerId
      }))
    }

    const startDate = data.startDate as Dayjs
    const endDate = data.endDate as Dayjs

    // Save Campaign
    const saveCampaignFields = {
      campaignTypesId: 3,
      companiesId: currentUser.userDetails.companiesId,
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
      creativeAspectRatio: data.storeArea?.displayRotation,
      isGlobal: false,
      name: data.name,
      selection,
      storeAreasId: data.storeArea?.storeAreaId,
      ...(assetId ? { tmpVideo: assetId } : {}),
      ...(duration ? { duration } : {}),
      ...(campaignId ? { id: campaignId } : {})
    }

    const saveCampaignResponse = await saveCampaign({
      fields: saveCampaignFields
    })

    if (saveCampaignResponse.error?.message) {
      setFailedToPublish(true)
    } else if (saveCampaignResponse.data.saveCampaignDetails) {
      const trackingData = {
        campaignStartDate: saveCampaignFields.startDate,
        campaignEndDate: saveCampaignFields.endDate,
        campaignName: saveCampaignResponse.data.saveCampaignDetails.name,
        campaignStoreCount:
          saveCampaignResponse.data.saveCampaignDetails.activeStores?.length,
        storeAreasId: saveCampaignFields.storeAreasId
      }
      if (isUpdateOperation) {
        saveCampaignResponse.data.saveCampaignDetails

        amplitude.track("editCampaign", trackingData)
        localStorage.setItem("PublishChange", "true")
        window.location.reload()
      } else {
        amplitude.track("createCampaign", trackingData)
        onDidSuccessfullyPublishCampaign(
          saveCampaignResponse.data.saveCampaignDetails
        )
      }
    }
  }

  const targetableStoreAreas = React.useMemo(() => {
    return campaignStoreAreas ?? []
  }, [campaignStoreAreas])

  const onSubmit = () => {
    setIsSubmitting(true)
    setLoading(true)
  }

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const handleDiscardCampaign = () => {
    if (campaignId) {
      localStorage.setItem("discardChange", "true")
      window.location.reload()
    } else {
      navigate(-1)
    }
  }

  const handleBackToCampaignList = () => {
    if (isCancelDisabled || isSubmitDisabled) {
      navigate("/")
    } else {
      setOpenModal(true)
    }
  }

  const loaderTitle = campaignId
    ? "Publishing changes…"
    : "Publishing campaign…"

  const loaderContent =
    "Please do not close or refresh this page until it finishes."

  return (
    <FormProvider {...formMethods}>
      <Loader
        active={
          loading ||
          campaignFetching ||
          bannersFetching ||
          campaignStoreAreas === undefined
        }
        title={isSubmitting ? loaderTitle : undefined}
        content={isSubmitting ? loaderContent : undefined}
      />
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <EditCampaignNavBar
            campaignId={campaignId}
            onUserCancel={
              formState.isDirty ? handleModalOpen : handleBackToCampaignList
            }
            publishDisabled={isSubmitDisabled}
            cancelDisabled={isCancelDisabled}
          />
          {storeLoadingError && (
            <ErrorBanner>
              <Typography variant="body2" color="error">
                Something went wrong. Check your internet connection and refresh
                the page.If the problem persists contact
                <Link
                  href="mailto:support@grocerytv.com"
                  color="error"
                  underline="none"
                >
                  support@grocerytv.com
                </Link>
              </Typography>
            </ErrorBanner>
          )}
          {failedToUploadAsset && (
            <Box>
              <ErrorBanner>
                <Typography variant="body2" color="error">
                  There was an issue uploading asset, please try again.If the
                  problem persists contact support@grocerytv.com
                </Typography>
              </ErrorBanner>
            </Box>
          )}
          {failedToPublish && (
            <Box>
              <ErrorBanner>
                <Typography variant="body2" color="error">
                  There was an issue publishing this campaign, please try again.
                </Typography>
              </ErrorBanner>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              lineHeight: "64px",
              marginTop: "64px",
              justifyContent: "space-around",
              flexDirection: "column"
            }}
            gap="48px"
            display="flex"
          >
            {campaignId && (
              <Box
                sx={{
                  position: "sticky",
                  zIndex: 1000,
                  top: "64px",
                  backgroundColor: "secondary.main",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  boxShadow: showBoxShadow
                    ? "-2px 10px 5px -6px rgba(0,0,0,0.1)"
                    : "none"
                }}
                display="flex"
                flexDirection="column"
                width="100%"
              >
                <Box
                  sx={{
                    padding: "0px 10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        width: "100%"
                      }}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        height="32px"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: "62%" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                          onClick={handleBackToCampaignList}
                        >
                          <ArrowLeft
                            sx={{
                              color: "text.secondary",
                              mr: "4px",
                              width: "16px",
                              height: "16px"
                            }}
                          />
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            All Campaigns
                          </Typography>
                        </Box>
                        {formState.isDirty && campaignId && (
                          <Box>
                            <WarningBanner>
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "hint.dark" }}
                              >
                                This campaign has unpublished changes.
                              </Typography>
                            </WarningBanner>
                          </Box>
                        )}
                        {discardUnSavedChange && (
                          <Box>
                            <DiscardBanner>
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "secondary.main" }}
                              >
                                Changes discarded
                              </Typography>
                            </DiscardBanner>
                          </Box>
                        )}
                        {publishChange && (
                          <Box>
                            <SuccessChangeBanner>
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "secondary.main" }}
                              >
                                Changes were successfully published.
                              </Typography>
                            </SuccessChangeBanner>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              <Box
                sx={{
                  maxWidth: "906px"
                }}
                display="flex"
                gap="30px"
                flexDirection="column"
              >
                <Box>
                  <StatusBadge status={campaignStatus || "Draft"} />
                </Box>
                <CampaignForm
                  campaignStatus={campaignStatus}
                  currentStartDate={currentStartDate}
                  currentEndDate={currentEndDate}
                />
                {campaignId === undefined && (
                  <StoreAreaSelector
                    storeAreas={targetableStoreAreas}
                    editable={campaignId === undefined}
                  />
                )}

                {storeArea?.hasScreenLevelTargeting && (
                  <ScreenLocationSelector
                    screenLocations={getScreenLocationsByStoreArea}
                    disabled={false}
                  />
                )}

                {((storeArea && !storeArea.hasScreenLevelTargeting) ||
                  (selectedScreenLocation?.length ?? 0) > 0) && (
                  <TargetSelector
                    campaignStatus={campaignStatus}
                    storeAreas={campaignStoreAreas}
                    campaignStores={filteredStores}
                    hasScreenLevelTargeting={false}
                    isLoadingStores={isLoadingStores}
                    properties={properties}
                  />
                )}
                {fileError && errorComponentMap[fileError.type] && (
                  <ErrorBanner>
                    {React.createElement(errorComponentMap[fileError.type], {
                      name: fileError.fileName
                    })}
                  </ErrorBanner>
                )}
                <CampaignCreative
                  campaignStatus={campaignStatus}
                  handleFileError={handleFileError}
                  uploadFile={handleFileUpload}
                  uploadFileQueue={uploadFileQueue}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
      <ConfirmationModal
        open={openModal}
        title={
          campaignId
            ? "Changes will be discarded"
            : "New campaign will be discarded"
        }
        content={
          campaignId
            ? "Campaign changes have not been published yet. If you cancel it now, it will be discarded."
            : "This campaign has not been published yet. If you leave now it will be discarded."
        }
        cancelButtonText="Continue Editing"
        confirmButtonText={campaignId ? "Discard Changes" : "Discard Campaign"}
        confirmButtonVariant="warning"
        onCancel={handleModalClose}
        onConfirm={handleDiscardCampaign}
      />
    </FormProvider>
  )
}

export default EditCampaign
