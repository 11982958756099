import React from "react"
import { Box, Typography, Button } from "@mui/material"
import AssetGuidelinesModal from "../AssetGuidelinesModal"
import CircleInfoFill from "../../icons/CircleInfoFill"
import { StoreArea } from "../../../models/storearea"

type CreativeSectionHeaderProps = {
  storeArea?: StoreArea
  asset?: string
}

const assetOrientation = (storeArea: StoreArea) => {
  switch (storeArea.displayRotation) {
    case "9:16":
      return {
        content: "portrait orientation that has a 9:16 aspect ratio.",
        modalName: "Portrait assets here."
      }
    case "16:9":
      return {
        content: "landscape orientation that has a 16:9 aspect ratio.",
        modalName: "Landscape assets here."
      }
  }
}

const CreativeSectionHeader = ({
  storeArea,
  asset
}: CreativeSectionHeaderProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      {asset ? (
        <Typography variant="body1" sx={{ fontSize: "18px" }}>
          Asset
        </Typography>
      ) : storeArea ? (
        <Typography variant="body2">
          Upload an image or video asset in
          <Typography variant="body2" sx={{ paddingX: "4px" }} component="span">
            {assetOrientation(storeArea).content}
          </Typography>
        </Typography>
      ) : (
        <Typography variant="body2">
          Select a
          <Typography
            variant="body2"
            sx={{ paddingX: "4px", color: "text.secondary", fontWeight: 600 }}
            component="span"
          >
            Store Area
          </Typography>
          <Typography variant="body2" component="span">
            to see what type of creative asset is needed for this campaign
          </Typography>
        </Typography>
      )}

      <Button
        variant="text"
        startIcon={<CircleInfoFill sx={{ width: "16px", height: "16px" }} />}
        onClick={handleModalOpen}
        sx={{
          color: "text.secondary",
          padding: "6px 0",
          fontWeight: 600
        }}
        disabled={!storeArea}
      >
        Asset Guidelines
      </Button>
      <AssetGuidelinesModal
        open={openModal}
        orientation={storeArea?.displayRotation}
        durationMinmum={storeArea?.minCreativeDurationMs}
        durationMaxmum={storeArea?.maxCreativeDurationMs}
        onClose={handleModalClose}
      />
    </Box>
  )
}

export default CreativeSectionHeader
