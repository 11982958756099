import React from "react"
import { Box, Typography } from "@mui/material"
import TargetGrid from "../../targetGrid"
import { NewCampaignStore, Status } from "../../../models/campaign"
import { StoreArea } from "../../../models/storearea"

interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}
interface Props {
  storeAreas?: StoreArea[]
  campaignStores?: NewCampaignStore[]
  campaignStatus?: Status
  hasScreenLevelTargeting: boolean
  isLoadingStores: boolean
  properties: PropertyApiResponse[] | undefined
}

const TargetSelector = ({
  storeAreas,
  campaignStores,
  campaignStatus,
  isLoadingStores,
  properties
}: Props) => {
  if (!storeAreas) {
    return null
  }

  if (!campaignStores) {
    return null
  }

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "30px"
      }}
      data-cy="store-area-selector"
    >
      <Typography
        sx={{
          fontSize: "18px"
        }}
        fontSize={18}
        data-cy="targeting-selector"
      >
        Stores
      </Typography>
      <Box
        sx={{
          flex: "0 0 auto",
          gap: "19px",
          width: "100%"
        }}
      >
        <TargetGrid
          data={campaignStores}
          isLoadingStores={isLoadingStores}
          campaignStatus={campaignStatus}
          properties={properties}
        />
      </Box>
    </Box>
  )
}

export default TargetSelector
