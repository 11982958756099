import React from "react"
import { Box, Typography } from "@mui/material"
import ImageOutline from "../../icons/ImageOutline/ImageOutline"
import VideoOutline from "../../icons/VideoOutline/VideoOutline"
import CloudUpload from "../../icons/CloudUpload"
import { CampaignFormModel } from "../../../models/campaign"
import { useFormContext } from "react-hook-form"

export type FileUploadProps = {
  accept: string
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDrop?: (event: React.DragEvent<HTMLElement>) => void
  isDragging: boolean
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
}

const CampaignAssetUploader: React.FC<FileUploadProps> = ({
  accept = ".png, .jpg, .jpeg",
  handleChange,
  handleDrop,
  isDragging,
  setIsDragging
}) => {
  const { watch } = useFormContext<CampaignFormModel>()
  const storeArea = watch("storeArea")
  const isDisabled = !storeArea

  return (
    <>
      <input
        onChange={handleChange}
        accept={accept}
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        disabled={isDisabled}
        data-testid="file-upload"
      />

      <Box
        data-testid="file-uploader-input"
        component={"label"}
        htmlFor="file-upload"
        onDragOver={isDisabled ? undefined : (e) => e.preventDefault()}
        onDrop={isDisabled ? undefined : handleDrop}
        onDragEnter={() => !isDisabled && setIsDragging(true)}
        onDragLeave={() => !isDisabled && setIsDragging(false)}
        gap="10px"
        sx={{
          cursor: isDisabled ? "default" : "pointer",
          textAlign: "center",
          display: "flex",
          border: "none",
          backgroundImage: isDragging
            ? "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23109AB5FF' stroke-width='4' stroke-dasharray='6%2c 8.3' stroke-dashoffset='1' stroke-linecap='round'/%3e%3c/svg%3e\")"
            : "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23BFBFC9FF' stroke-width='3' stroke-dasharray='6%2c 8.3' stroke-dashoffset='1' stroke-linecap='round'/%3e%3c/svg%3e\")",
          backgroundColor: isDragging ? "fileInput.main" : "inherit",
          borderRadius: "8px",
          height: "193px",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px",
          "&:hover": {
            backgroundColor: !isDisabled && "fileInput.main",
            color: !isDisabled && "text.secondary",
            textDecoration: !isDisabled && "underline",
            backgroundImage:
              !isDisabled &&
              "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23109AB5FF' stroke-width='3' stroke-dasharray='6%2c 8.3' stroke-dashoffset='1' stroke-linecap='round'/%3e%3c/svg%3e\")"
          }
        }}
      >
        <Box sx={{ pointerEvents: "none" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              width: "100%",
              gap: "4px"
            }}
          >
            {isDragging ? (
              <Box
                display="flex"
                width="20px"
                height="20px"
                color="text.secondary"
              >
                <CloudUpload />
              </Box>
            ) : (
              <Box display="flex" gap="8px" lineHeight="20px">
                <Box width="20px" height="20px" color="text.primary">
                  <ImageOutline
                    sx={{
                      color: isDisabled ? "text.disabled" : "text.secondary"
                    }}
                  />
                </Box>
                <Box width="20px" height="20px" color="text.primary">
                  <VideoOutline
                    sx={{
                      color: isDisabled ? "text.disabled" : "text.secondary"
                    }}
                  />
                </Box>{" "}
              </Box>
            )}

            {isDragging ? (
              <Typography variant="body2" color="text.secondary">
                Drop your files here
              </Typography>
            ) : (
              <Typography
                component="span"
                variant="body2"
                color={isDisabled ? "text.disabled" : "text.secondary"}
              >
                Browse for a file
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CampaignAssetUploader
