import { SupportedAssetType } from "../models/campaign"
import { isEmpty, every, isUndefined, isNull, isArray } from "lodash"

export const getUserId = () => {
  const id = localStorage.getItem("user-id")
  if (!id) {
    return null
  }
  return Number(id)
}

export const isSupportedAsset = (
  fileType: string
): fileType is SupportedAssetType =>
  ["video/mp4", "image/png", "image/jpeg"].includes(fileType)

export const getUserName = () => {
  const name = localStorage.getItem("user-name")
  if (!name) {
    return null
  }
  return name
}

export const getInitials = (name: string | undefined | null): string => {
  if (!name) {
    return ""
  }

  const parts = name.split(" ")

  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase()
  }

  const firstName = parts[0]
  const lastName = parts[parts.length - 1]

  return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase()
}

export const getFirstAndLastName = (
  name: string | undefined | null
): { firstName: string; lastName: string } => {
  let firstName = ""
  let lastName = ""

  if (name) {
    const parts = name.split(" ")

    if (parts.length === 1) {
      firstName = parts[0]
    } else {
      firstName = parts[0]
      lastName = parts[parts.length - 1]
    }
  }

  return { firstName, lastName }
}

export const isEmptyObject = (obj: Record<string, unknown>): boolean => {
  return every(obj, (value: unknown) => {
    return (
      isUndefined(value) ||
      isNull(value) ||
      (isArray(value) && isEmpty(value)) ||
      value === ""
    )
  })
}

export const formatSeconds = (seconds: number) => {
  return `00:${seconds.toString().padStart(2, "0")}`
}

export const getAdjustedAspectRatioString = (
  widthPx: number,
  heightPx: number
) => {
  const aspectRatio16x9 = 16 / 9
  const aspectRatio9x16 = 9 / 16
  const aspectRatioDeviationThreshold = 0.05
  const actualAspectRatio = widthPx / heightPx
  const gcd = gcdCalc(widthPx, heightPx)

  if (
    Math.abs(actualAspectRatio - aspectRatio16x9) <
    aspectRatioDeviationThreshold
  ) {
    return "16:9"
  }

  if (
    Math.abs(actualAspectRatio - aspectRatio9x16) <
    aspectRatioDeviationThreshold
  ) {
    return "9:16"
  }

  return `${widthPx / gcd}:${heightPx / gcd}`
}

/**
 * Helper function to calculate the greatest common divisor (GCD)
 * @param {number} a
 * @param {number} b
 * @returns {number} The GCD of a and b
 */
function gcdCalc(a: number, b: number): number {
  if (!b) {
    return a
  }
  return gcdCalc(b, a % b)
}

export const generateDurationList = (
  minRange: number,
  maxRange: number
): number[] => {
  const result: number[] = []

  // Convert milliseconds to seconds
  const minSeconds = Math.ceil(minRange / 1000)
  const maxSeconds = Math.floor(maxRange / 1000)

  // Generate the range list
  for (let i = minSeconds; i <= maxSeconds; i++) {
    result.push(i)
  }

  return result
}

export const extractStringFirstToken = (str: string): string => {
  return str.split(" ")[0]
}

export const alphabeticalSort = (lhs: string, rhs: string): number => {
  return lhs.localeCompare(rhs, undefined, {
    // Sort numbers in the string by their numeric value
    numeric: true,
    sensitivity: "base"
  })
}
