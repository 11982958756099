import React from "react"
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { NewCampaignStore, Status } from "../../../models/campaign"

interface Props {
  target: NewCampaignStore
  selected: boolean
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  campaignStatus?: Status
}

const Row = ({ target, selected, onChange, campaignStatus }: Props) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1.5fr 1.5fr 1fr",
        padding: "2px 0",
        borderTop: "1px solid",
        borderColor: "secondary.light",
        backgroundColor: selected ? "secondary.contrastText" : "secondary.main"
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            disabled={campaignStatus === "Ended"}
            checked={selected}
            size="small"
            sx={{
              color: "text.primary",
              fontSize: "14px",
              "&.Mui-checked": {
                color: "primary.light"
              }
            }}
            onChange={onChange}
          />
        }
        disableTypography={true}
        label={target.banner?.name}
        title={target.banner?.name}
        sx={{
          display: "block",
          padding: "0 12px",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "41px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}
      />

      <Typography
        title={target.name}
        sx={{
          lineHeight: "41px",
          textAlign: "left",
          padding: "0 12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}
        variant="body2"
      >
        {target.name}
      </Typography>

      <Typography
        title={`${target.address?.city}, ${target.address?.state}`}
        sx={{
          lineHeight: "41px",
          textAlign: "left",
          padding: "0 12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}
        variant="body2"
      >{`${target.address?.city}, ${target.address?.state}`}</Typography>
    </Box>
  )
}

export default Row
